var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cover-overlay z-13",on:{"click":_vm.closeModal}},[_c('button',{staticClass:"block lg:hidden absolute top-1 left-1 text-2xl text-white font-extrabold px-5 py-3 rounded-full bg-richBlack",on:{"click":_vm.closeModal}},[_vm._v(" x ")]),_c('div',{staticClass:"rounded-xl lg:rounded-none bg-richBlack pt-11 px-4 pb-4 inner",on:{"click":function($event){$event.stopPropagation();}}},[_vm._m(0),_c('section',{staticClass:"block lg:flex justify-evenly mt-6 lg:mt-18"},[_c('section',{staticClass:"bg-ansGreen w-12/12 lg:w-4/12 rounded-xl flex flex-col justify-center items-center py-8"},[_c('img',{staticClass:"h-10 w-10",attrs:{"src":require("@/assets/images/icons/business.svg"),"alt":"icons"}}),_vm._m(1),_c('p',{staticClass:"text-center font-medium text-sm text-iguGreen px-10 mt-2.5"},[_vm._v(" Use this if your business is is not yet incorporated ")]),_c('router-link',{staticClass:"mt-5 rounded-xl bg-ansLemon py-3.5 text-white px-14",attrs:{"to":{
            name: 'register-unregistered-business',
            query: { st: _vm.page },
          }},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_vm._v(" Start ")])],1),_c('section',{staticClass:"bg-ansGreen w-12/12 lg:w-4/12 rounded-xl flex flex-col justify-center items-center py-8 mt-7 lg:mt-0"},[_c('img',{staticClass:"h-10 w-10",attrs:{"src":require("@/assets/images/icons/business.svg"),"alt":"icons"}}),_vm._m(2),_c('p',{staticClass:"text-center font-medium text-sm text-iguGreen px-10 mt-2.5"},[_vm._v(" Use this if your business is already incorporated ")]),_c('router-link',{staticClass:"mt-5 rounded-xl bg-ansLemon py-3.5 text-white px-14",attrs:{"to":{ name: 'register-registered-business', query: { st: 1 } }},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_vm._v(" Start ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-white w-full text-center"},[_c('div',{staticClass:"text-xl lg:text-4xl font-bold"},[_vm._v(" You are about to register"),_c('br'),_vm._v(" a new signage ")]),_c('div',{staticClass:"mt-6 lg:mt-14 font-medium text-base lg:text-xl"},[_vm._v(" Choose a category below ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"text-center text-white font-bold mt-5"},[_vm._v(" Signage for "),_c('br'),_vm._v(" Un-Registered Business ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"text-center text-white font-bold mt-5"},[_vm._v(" Signage for "),_c('br'),_vm._v(" Registered Business ")])
}]

export { render, staticRenderFns }