<template>
  <div class="p-4 lg:px-18 lg:pt-8 block lg:flex">
    <section class="w-12/12 lg:w-8/12">
      <TransactionActivities
        :showSearch="true"
        :transactions="transactions"
        :getStat="getting"
      />
    </section>

    <section class="w-12/12 lg:w-4/12 ml-0 lg:ml-5">
      <GreenBox class="hidden lg:flex" />
    </section>
  </div>
</template>

<script>
import GreenBox from "@/components/app/dashboard/GreenBox.vue";
import TransactionActivities from "@/components/app/dashboard/TransactionActivities.vue";
export default {
  name: "Transactions",

  components: {
    GreenBox,
    TransactionActivities,
  },

  async created() {
    await this.getTransactions();
  },

  data() {
    return {
      getting: true,
      transactions: [],
    };
  },

  methods: {
    async getTransactions() {
      try {
        this.getting = true;

        const res = await this.$http.post("/get-transactions", {
          userId: this.$store.getters.userId,
          phone: this.$store.getters.userPhone,
        });
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.transactions = data.message;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped></style>
