<template>
  <div class="">
    <div class="flex justify-between items-center">
      <div class="text-xl font-bold text-pineGray">Transaction activity</div>

      <div>
        <div
          v-if="showSearch"
          class="rounded-lg bg-green-200 text-ansGreen text-xs py-2 px-5"
        >
          {{ transactions.length }}
        </div>

        <router-link
          :to="{ name: 'transactions' }"
          v-else
          class="rounded-lg block bg-green-200 text-ansGreen text-xs py-2 px-2.5"
        >
          See All
        </router-link>
      </div>
    </div>

    <Search v-if="showSearch" v-model="searchTerm" class="mt-2" />

    <div>
      <div v-if="getStat" class="flex justify-center w-full mt-7">
        <spinner class="w-16 h-16" color="ansGreen" />
      </div>

      <div v-else class="mt-9">
        <router-link
          v-for="(trans, n) in filteredTransactions"
          :to="{ name: 'view-transaction', params: { id: trans.id } }"
          :key="n"
          class="transaction"
        >
          <div class="flex">
            <div class="bg-red-50 rounded-lg p-3.5 mr-2">
              <img
                src="@/assets/images/icons/arrow-tl.svg"
                alt="icon"
                class="h-3.5 w-3.5"
              />
            </div>
            <div class="text-darkCharcoal">
              <h4 class="font-bold text-sm">
                {{ trans.asset_code }} {{ trans.invoice_number }}
              </h4>
              <p class="text-xs">Successfull</p>
            </div>
          </div>

          <div>
            <h4>{{ trans.amount | toCurrency }}</h4>
            <p>{{ trans.dateof_subscription | moment("DD/MM/YYYY") }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/form/Search";
export default {
  name: "TransactionActivities",
  components: {
    Search,
  },
  props: {
    getStat: {
      tyype: Boolean,
      default: true,
    },
    transactions: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: null,
    };
  },
  computed: {
    filteredTransactions() {
      if (!this.searchTerm) return this.transactions;

      return this.transactions.filter((sign) => {
        return Object.values(sign).find((vs) => {
          return `${vs}`.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      });
    },
  },
};
</script>

<style scoped>
.transaction {
  @apply flex justify-between items-center mt-4 rounded-xl p-2;
  border: 1px solid #f0f1f3;
}
.transaction > div:last-child h4 {
  @apply font-bold text-sm text-darkCharcoal;
}
.transaction > div:last-child p {
  @apply text-xs;
  color: #a7a7a7;
}
</style>
