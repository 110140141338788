<template>
  <div class="block lg:flex justify-evenly items-start px-8 lg:px-12 mt-7">
    <section class="w-12/12 lg:w-7/12 border border-turqGreen rounded-xl p-8">
      <div class="flex justify-center py-1">
        <img
          :src="
            $store.getters.userLogo
              ? $store.getters.userLogo
              : require('@/assets/images/null_female.png')
          "
          alt="user picture"
          style="object-fit: contain"
          class="h-28 w-28 rounded-full border"
        />
      </div>

      <div v-if="$store.getters.ninSet > 0" class="mt-6">
        <label for="name">NIN</label>
        <input
          type="text"
          class="input"
          readonly
          v-model="user.nin"
          id="name"
        />
      </div>

      <div class="mt-6">
        <label for="name">Full name</label>
        <input
          type="text"
          class="input"
          readonly
          v-model="user.name"
          id="name"
        />
      </div>

      <div class="mt-6">
        <label for="email">Email</label>
        <input
          type="email"
          class="input"
          readonly
          v-model="user.email"
          id="email"
        />
      </div>

      <div class="mt-6">
        <label for="phone">Phone</label>
        <input
          type="number"
          class="input"
          readonly
          v-model="user.phone"
          id="phone"
          pattern="/\d*$/"
          onKeyPress="if(this.value.length==11) return false;"
        />
      </div>

      <div v-if="$store.getters.ninSet == 1" class="mt-6 flex justify-center">
        <button
          @click="gotoVnin"
          class="underline text-center text-ansLemon font-bold"
        >
          Add Virtual NIN
        </button>
      </div>

      <!-- NIN Edit section -->
      <div v-if="!user.nin" class="border-t border-ansLemon mt-6">
        <div class="mt-6">
          <label for="nin">NIN</label>
          <div
            class="flex justify-between items-center bg-ghostWhite rounded-xl pr-2.5"
          >
            <input
              autofocus
              type="number"
              class="input"
              id="nin"
              pattern="/^-?\d+\.?\d*$/"
              onKeyPress="if(this.value.length==11) return false;"
              placeholder="Enter your 11-digit NIN"
              v-model="form.nin"
            />

            <button
              :disabled="!ninReady"
              @click="verifyNin"
              class="font-medium text-white px-4 py-1 bg-ansLemon rounded-md"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="w-12/12 lg:w-4/12 mt-8 lg:mt-0">
      <div class="border border-turqGreen rounded-xl">
        <div class="flex justify-between items-center px-6 py-4 rounded-t-xl">
          <h5 class="font-bold text-center w-full">Account Summary</h5>
        </div>

        <div class="px-6 pt-3 pb-6">
          <div
            class="rounded-xl flex gap-4 bg-ansGreen border border-ansGreen text-white text-sm w-full mt-1 p-4"
          >
            <img src="@/assets/images/icons/dollar_white.svg" alt="icons" />

            <p class="text-xs">All payments are up to date.</p>
          </div>

          <div
            class="rounded-xl flex justify-between border border-ansGreen p-4 mt-6 text-cadet text-sm"
          >
            <!-- <p>Transactions completed</p> -->
            <p>Transactions count</p>
            <p class="font-bold">{{ $store.state.profile.unpaid_invoices }}</p>
          </div>
        </div>
      </div>

      <div class="border border-turqGreen rounded-xl p-4 mt-5">
        <h3 class="font-bold w-full text-center">Wallet balance</h3>

        <div
          class="flex justify-between bg-nishGreen rounded-xl mt-4 px-4 py-3 text-white"
        >
          <div class="font-bold">NGN 200.00</div>

          <button class="text-xs font-medium px-2 py-1 bg-ansLemon">
            Top up
          </button>
        </div>

        <div class="w-full text-center font-medium my-8 text-cadet text-xs">
          View your wallet history
        </div>
      </div>

      <div class="border border-turqGreen rounded-xl p-4 mt-5">
        <h3 class="font-bold w-full text-center">Business Linkages</h3>

        <router-link
          :to="{ name: 'request-business-linkage' }"
          class="flex justify-between bg-nishGreen rounded-xl mt-4 px-4 py-3 text-white"
        >
          <div class="font-bold">Request business linkage</div>

          <div>
            <img src="@/assets/images/icons/send.svg" alt="icons" />
          </div>
        </router-link>

        <router-link
          :to="{ name: 'business-linkages' }"
          class="w-full block text-center font-medium my-8 text-cadet text-xs"
        >
          View linkage requests
        </router-link>
      </div>

      <div class="border border-turqGreen rounded-xl p-4 my-5">
        <h3 class="font-bold w-full text-center">Facing any challenges?</h3>

        <div
          class="flex justify-between bg-nishGreen rounded-xl mt-4 px-4 py-3 text-white"
        >
          <div class="font-bold">Send a message to our support</div>

          <div>
            <img src="@/assets/images/icons/send.svg" alt="icons" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Profile",

  data() {
    return {
      showit: false,
      user: {
        nin: this.$store.state.profile.nin,
        name: this.$store.state.user.name,
        email: this.$store.state.user.email,
        phone: this.$store.state.user.phone,
      },
      form: {
        nin: null,
      },
    };
  },

  computed: {
    ninReady() {
      return this.form.nin && this.form.nin.length == 11;
    },
  },

  methods: {
    verifyNin() {
      try {
        this.$store.commit("addTonewRegister", {
          ...this.form,
          from: "profile",
        });

        this.$router.push({
          name: "register-unregistered-business",
          query: { st: 2 },
        });
      } catch (err) {
        console.log(err);
      }
    },
    gotoVnin() {
      this.$store.commit("addTonewRegister", {
        nin: this.user.nin,
        from: "profile",
      });

      this.$router.push({
        name: "register-unregistered-business",
        query: { st: 3 },
      });
    },
  },
};
</script>

<style scoped>
#nin:focus {
  border: transparent;
}
</style>
