<template>
  <div class="p-4 lg:px-18 lg:pt-8 block lg:flex">
    <section class="w-12/12 lg:w-8/12">
      <div class="flex justify-between items-center">
        <div class="flex gap-3 items-center">
          <button @click="$router.go(-1)" class="py-2 px-3">
            <img
              src="@/assets/images/icons/arrow-top-left-sharp.svg"
              alt="icons"
              class="h-6 w-6"
            />
          </button>

          <h4 class="font-bold text-base lg:text-xl">
            <span class="text-pineGray mr-3">Transaction ID</span>
            <span>1230193190</span>
          </h4>
        </div>

        <button class="bg-aeroBlue py-1.5 px-2.5 rounded-lg">
          <img
            src="@/assets/images/icons/share.svg"
            alt="icons"
            class="w-5 h-5"
          />
        </button>
      </div>

      <Lines class="" />

      <div>
        <h4 class="font-bold text-base lg:text-xl mb-4 lg:my-8">
          Transaction details
        </h4>

        <div>
          <div class="key">Invoice Number</div>
          <div class="val">Invoice 23242: GGG23208</div>
        </div>

        <div class="mt-5">
          <div class="key">Mode of Payment</div>
          <div class="val">Card payment</div>
        </div>

        <div class="mt-5">
          <div class="key">Date and Time</div>
          <div class="val">12/03/2023; 12:03 pm</div>
        </div>

        <div class="mt-5">
          <div class="key">Transaction status</div>
          <div class="val success">Successful</div>
          <div class="val failed">Failed</div>
        </div>
      </div>
    </section>

    <section class="w-12/12 lg:w-4/12 ml-0 lg:ml-5"></section>
  </div>
</template>

<script>
import Lines from "@/components/Lines.vue";
export default {
  name: "ViewTransaction",

  components: {
    Lines,
  },

  props: ["id"],
};
</script>

<style scoped>
.key {
  @apply rounded-xl w-full p-2.5 bg-white text-cadet font-bold text-xs;
  border: 8px solid #f3fef5;
}
.val {
  @apply rounded-xl w-full p-2.5 bg-ghostWhite mt-4 font-bold text-xs lg:text-base;
  border: 8px solid #f9f9f9;
}
.val.success {
  @apply bg-ansLemon text-white;
}
.val.failed {
  @apply bg-red-300 text-red-700;
}
</style>
