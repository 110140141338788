<template>
  <header class="fixed w-full z-12">
    <section
      class="bg-white flex justify-between items-center p-4 lg:px-18 lg:py-5"
    >
      <div>
        <router-link to="/app" class="flex items-center">
          <img
            src="@/assets/images/logo.svg"
            alt="ANSAA logo"
            class="h-8 w-11 lg:w-22 lg:h-16"
          />
          <img
            src="@/assets/images/logo_text-black.svg"
            alt="ANSAA logo"
            class="w-18 h-5 ml-2 lg:w-40 lg:h-11"
          />
        </router-link>
      </div>

      <div class="relative flex justify-between mt-0 lg:mt-2">
        <!-- <div
          class="bg-green-50 hidden lg:flex rounded-xl h-12 items-center pl-5 pr-2"
        >
          <span class="text-darkCharcoal text-base p-0 m-0">My Account</span>

          <button class="p-3 pr-4">
            <img
              src="@/assets/images/icons/drop-arrow.svg"
              alt="icons"
              class="w-3 h-2"
            />
          </button>
        </div> -->

        <div class="ml-8">
          <button @click="toggleMenuModal" class="px-2 py-1">
            <img
              src="@/assets/images/icons/hamburger.svg"
              alt="icons"
              class="w-10"
            />
          </button>
        </div>

        <transition name="slidey">
          <MenuModal v-if="$store.getters.menuModal" />
        </transition>
      </div>
    </section>

    <nav class="bg-ghostWhite pl-0 pr-7 lg:px-18">
      <ul class="flex justify-between items-center">
        <div class="flex">
          <li>
            <router-link
              :to="{ name: 'dashboard' }"
              :class="$route.name == 'dashboard' ? 'active' : ''"
            >
              Dashboard
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'transactions' }"
              :class="$route.name.includes('transaction') ? 'active' : ''"
            >
              Transactions
            </router-link>
          </li>
        </div>
        <li class="block lg:hidden">
          <button @click="openSignage" class="underline">
            Register new signage
          </button>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import MenuModal from "@/components/app/MenuModal.vue";
export default {
  name: "AppHeader",

  components: {
    MenuModal,
  },

  methods: {
    openSignage() {
      this.$store.commit("toggleSignageModal");
    },
    toggleMenuModal() {
      this.$store.commit("toggleMenuModal");
    },
  },
};
</script>

<style scoped>
ul div li a {
  @apply block text-cadet text-xs lg:text-base py-2 px-5 lg:py-3.5 lg:px-6;
}
ul li button {
  @apply block text-ansGreen text-xs font-medium px-1;
}
ul li a.active {
  @apply text-white bg-ansGreen font-normal lg:font-bold border-b-4 border-ansBlack;
  transition: background 0.5s ease-in-out;
}

/* slide styles */
.slidey-leave-active,
.slidey-enter-active {
  transition: 0.5s;
}
.slidey-enter {
  transform: translate(0, -100%);
}
.slidey-leave-to {
  transform: translate(0, -100%);
}
</style>
