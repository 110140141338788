<template>
  <div class="menu-box">
    <ul class="w-full">
      <li>
        <router-link
          @click.native="toggleMenuModal"
          :to="{ name: 'profile' }"
          class=""
        >
          Profile
        </router-link>
      </li>

      <li>
        <button @click="logout" class="">Logout</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuModal",

  methods: {
    toggleMenuModal() {
      this.$store.commit("toggleMenuModal");
    },

    async logout() {
      this.toggleMenuModal();
      await this.$store.dispatch("logout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.menu-box {
  @apply py-5 absolute top-9 right-0 w-80;
  background: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
}

li a,
li button {
  @apply w-full py-3.5 text-center block;
  color: #101011;
}
li a {
  border-bottom: 1px solid #cdffe7;
}
</style>
