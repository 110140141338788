<template>
  <div>
    <transition name="slidex">
      <GreenModal v-if="$store.getters.signageModal" />
    </transition>

    <AppHeader />

    <!-- <transition name="slidey">
      <MenuModal v-if="$store.getters.menuModal" />
    </transition> -->

    <main class="pt-30 lg:pt-40 pb-10">
      <router-view />
    </main>
  </div>
</template>

<script>
import AppHeader from "@/components/app/AppHeader.vue";
import GreenModal from "@/components/app/GreenModal.vue";

export default {
  name: "AppFrame",

  components: {
    AppHeader,
    GreenModal,
  },
};
</script>

<style scoped>
.slidex-leave-active,
.slidex-enter-active {
  transition: 0.5s;
}
.slidex-enter {
  transform: translate(100%, 0);
}
.slidex-leave-to {
  transform: translate(-100%, 0);
}
</style>
