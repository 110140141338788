<template>
  <div class="p-4 lg:px-18 lg:pt-8">
    <section class="block lg:flex justify-between mb-8">
      <div class="w-full lg:w-8/12">
        <h3 class="texxt-base lg:text-3xl">
          <span class="text-green-500 font-medium">Welcome back</span>
          <span class="text-green-700 ml-2 font-bold">{{
            $store.state.user.name
          }}</span>
          <span>✌️</span>
        </h3>
      </div>

      <div
        v-if="showUnpaid"
        class="w-full lg:w-4/12 py-3 px-5 mt-3 lg:mt-0 bg-red-100 rounded-xl flex justify-between items-center"
      >
        <p class="p-0 m-0 font-bold text-xs text-black w-8/12 lg:9/12 truncate">
          Outstanding Invoices ({{ signageUnpaidCount }})
        </p>
        <div class="flex w-4/12 lg:w-3/12 justify-between">
          <router-link
            :to="{ name: 'invoices' }"
            class="text-white font-bold text-xs bg-nishGreen py-1 px-5 rounded-lg"
          >
            View
          </router-link>

          <button @click="showUnpaid = !showUnpaid">
            <img src="@/assets/images/icons/close.svg" alt="icons" />
          </button>
        </div>
      </div>
    </section>

    <div class="block lg:flex">
      <section class="w-12/12 lg:w-8/12">
        <div class="boxes">
          <router-link
            :to="{ name: 'businesses' }"
            class="box border border-darkGreen"
          >
            <div class="bg-darkGreen">Registered<br />Businesses</div>

            <div v-if="getting" class="flex justify-center w-full mt-7">
              <spinner class="w-12 h-12" color="ansGreen" />
            </div>
            <div v-else>{{ businessCount }}</div>
          </router-link>
          <router-link
            :to="{ name: 'signages' }"
            class="box border-ansBlack border"
          >
            <div class="bg-ansBlack">Registered<br />Signages</div>

            <div v-if="getting" class="flex justify-center w-full mt-7">
              <spinner class="w-12 h-12" color="ansBlack" />
            </div>
            <div v-else>{{ signageCount }}</div>
          </router-link>
        </div>

        <GreenBox class="flex lg:hidden mt-6" />

        <div class="mt-6 lg:mt-8">
          <div class="flex justify-between mb-3">
            <div class="text-xl font-bold text-pineGray">
              Your Signages ({{ signageCount }})
            </div>
            <router-link
              :to="{ name: 'signages' }"
              class="rounded-lg bg-green-200 text-ansGreen text-xs py-2 px-2.5"
            >
              See All
            </router-link>
          </div>

          <div
            v-if="signageCount > 0"
            class="block lg:flex justify-between pb-0 lg:pb-10"
          >
            <SignageCard
              :signage="{ ...signages[0] }"
              class="w-12/12 lg:w-6/12"
            />

            <SignageCard
              v-if="signageCount > 1"
              :signage="{ ...signages[1] }"
              class="w-12/12 lg:w-6/12 mt-3 lg:mt-0 ml-0 lg:ml-4"
            />
          </div>
        </div>
      </section>

      <section class="w-12/12 lg:w-4/12 mt-6 lg:mt-0 ml-0 lg:ml-4">
        <GreenBox class="hidden lg:flex" />

        <TransactionActivities
          :transactions="transactions"
          :getStat="getting"
          class="mt-7"
        />
      </section>
    </div>
  </div>
</template>

<script>
import GreenBox from "@/components/app/dashboard/GreenBox.vue";
import TransactionActivities from "@/components/app/dashboard/TransactionActivities.vue";
import SignageCard from "@/components/app/dashboard/SignageCard.vue";
export default {
  name: "Dashboard",

  components: {
    GreenBox,
    TransactionActivities,
    SignageCard,
  },

  async created() {
    await this.getData();
  },

  mounted() {
    this.$store.dispatch("getProfile");
    this.$store.commit("wipeRegister");
  },

  data() {
    return {
      getting: false,
      signages: [],
      businesses: [],
      transactions: [],
      businessCount: 0,
      signageCount: 0,
      signageUnpaidCount: 0,
    };
  },

  computed: {
    showUnpaid() {
      return this.signageUnpaidCount > 0;
    },
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post("/selfservice-dashboard", {
          userId: this.$store.getters.userId,
          phone: this.$store.getters.userPhone,
        });
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.signages = data.message.enumerated_assets;
        this.businesses = data.message.registered_businesses;
        this.transactions = data.message.transactions;
        this.businessCount = data.message.registered_business_count;
        this.signageCount = data.message.enumerated_asset_count;
        this.signageUnpaidCount = data.message.unpaid_invoices_count;
        this.$store.commit("saveBusinesses", this.businesses);
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.boxes {
  @apply flex justify-between;
  gap: 0.969rem;
}
.box {
  @apply w-1/2 rounded-xl block;
  /* max-width: 26.188rem; */
}
.box > div:first-child {
  @apply text-xs lg:text-4xl font-medium text-white text-center py-5 rounded-t-xl;
}
.box > div:last-child {
  @apply font-medium text-center text-2xl lg:text-6xl py-5 lg:py-18 rounded-t-xl;
  color: #252a2f;
}
</style>
