<template>
  <div class="p-4 lg:px-18 lg:pt-8 block lg:flex">
    <section class="w-12/12 lg:w-8/12">
      <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <button @click="$router.go(-1)" class="py-2 px-3">
            <img
              src="@/assets/images/icons/arrow-top-left-sharp.svg"
              alt="icons"
              class="h-6 w-6"
            />
          </button>
          <div class="text-xl font-bold text-pineGray">
            Request business linkage
          </div>
        </div>

        <div class="rounded-lg bg-green-200 text-ansGreen text-xs py-2 px-5">
          {{ businesses.length }}
        </div>
      </div>

      <Search
        v-if="showSearch"
        v-model="searchTerm"
        @input="getData"
        class="mt-2"
      />

      <div>
        <div v-if="getting" class="flex justify-center w-full mt-7">
          <spinner class="w-16 h-16" color="ansGreen" />
        </div>

        <div v-else-if="!getting && businesses.length" class="mt-9">
          <div
            v-for="(trans, n) in businesses"
            :key="n"
            class="flex justify-between items-center rounded-xl border border-gray-200 p-2 mb-3"
          >
            <div class="flex items-center gap-2">
              <div class="bg-green-50 rounded-lg p-2">
                <img src="@/assets/images/icons/bag.svg" alt="icon" />
              </div>

              <div class="text-darkCharcoal">
                <h4 class="font-bold text-xs">
                  {{ trans.businessName }}
                </h4>
                <p class="text-xs truncate">{{ trans.businessDescription }}</p>
              </div>
            </div>

            <div>
              <button
                @click="request(trans.id, trans.businessName)"
                class="bg-ansLemon text-white font-medium text-xs px-5 py-1 rounded"
              >
                Select
              </button>
            </div>
          </div>
        </div>

        <div v-else class="flex justify-center w-full mt-7">
          <p class="text-cadet">No record found</p>
        </div>
      </div>
    </section>

    <section class="w-12/12 lg:w-4/12 ml-0 lg:ml-5"></section>
  </div>
</template>

<script>
import Search from "@/components/form/Search";
export default {
  name: "RequestBusinessLinkageget",

  components: {
    Search,
  },

  data() {
    return {
      getting: false,
      showSearch: true,
      searchTerm: null,
      businesses: [],
    };
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post("/find-businesses", {
          searchQuery: this.searchTerm,
        });
        this.getting = false;

        if (!res) {
          this.businesses = [];
          return;
        }

        const { data } = res;
        this.businesses = data.message;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    async request(id, name) {
      try {
        const result = await this.$swal({
          icon: "question",
          title: name,
          text: "You are requesting that your account gets linked to the following business",
          showDenyButton: true,
          denyButtonText: "Cancel Linkage",
          confirmButtonText: "Request linkage",
          allowOutsideClick: false,
        });

        if (!result.isConfirmed) {
          return;
        }

        this.$store.commit("toggleOverlay");
        const res = await this.$http.post("/request-business-linkage", {
          businessId: id,
          userId: this.$store.getters.userId,
        });

        this.$store.commit("toggleOverlay");
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
