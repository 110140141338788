<template>
  <div class="p-4 lg:px-18 lg:pt-8 block lg:flex">
    <section class="w-12/12 lg:w-8/12">
      <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <button @click="$router.go(-1)" class="py-2 px-3">
            <img
              src="@/assets/images/icons/arrow-top-left-sharp.svg"
              alt="icons"
              class="h-6 w-6"
            />
          </button>
          <div class="text-xl font-bold text-pineGray">
            Business Linkage Requests
          </div>
        </div>

        <div class="rounded-lg bg-green-200 text-ansGreen text-xs py-2 px-5">
          {{ linkages.length }}
        </div>
      </div>

      <Search v-if="showSearch" v-model="searchTerm" class="mt-2" />

      <div>
        <div v-if="getting" class="flex justify-center w-full mt-7">
          <spinner class="w-16 h-16" color="ansGreen" />
        </div>

        <div v-else class="mt-9">
          <div
            v-for="(trans, n) in filteredLinkages"
            :key="n"
            class="flex justify-between items-center rounded-xl border border-gray-200 p-2 mb-3"
          >
            <div class="flex items-center gap-2">
              <div class="bg-green-50 rounded-lg p-2">
                <img src="@/assets/images/icons/bag.svg" alt="icon" />
              </div>

              <div class="text-darkCharcoal">
                <h4 class="font-bold text-xs">
                  {{ trans.businessName }}
                </h4>
                <p class="text-xs truncate">{{ trans.businessDescription }}</p>
              </div>
            </div>

            <div>
              <div
                v-if="trans.approval_status"
                class="bg-ansLemon text-white font-medium text-xs px-5 py-1 rounded"
              >
                Success
              </div>
              <div
                class="bg-cadet text-white font-medium text-xs px-5 py-1 rounded"
              >
                Pending
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="w-12/12 lg:w-4/12 ml-0 lg:ml-5"></section>
  </div>
</template>

<script>
import Search from "@/components/form/Search";
export default {
  name: "BusinessLinkages",

  components: {
    Search,
  },

  async created() {
    await this.getData();
  },

  data() {
    return {
      getting: false,
      showSearch: true,
      searchTerm: null,
      linkages: [],
    };
  },

  computed: {
    filteredLinkages() {
      if (!this.searchTerm) return this.linkages;

      return this.linkages.filter((sign) => {
        return Object.values(sign).find((vs) => {
          return `${vs}`.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      });
    },
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post("/get-requested-business", {
          userId: this.$store.getters.userId,
        });
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.linkages = data.data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped></style>
