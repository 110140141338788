<template>
  <div class="cover-overlay z-13" @click="closeModal">
    <button
      @click="closeModal"
      class="block lg:hidden absolute top-1 left-1 text-2xl text-white font-extrabold px-5 py-3 rounded-full bg-richBlack"
    >
      x
    </button>

    <div
      @click.stop
      class="rounded-xl lg:rounded-none bg-richBlack pt-11 px-4 pb-4 inner"
    >
      <section class="text-white w-full text-center">
        <div class="text-xl lg:text-4xl font-bold">
          You are about to register<br />
          a new signage
        </div>

        <div class="mt-6 lg:mt-14 font-medium text-base lg:text-xl">
          Choose a category below
        </div>
      </section>

      <section class="block lg:flex justify-evenly mt-6 lg:mt-18">
        <section
          class="bg-ansGreen w-12/12 lg:w-4/12 rounded-xl flex flex-col justify-center items-center py-8"
        >
          <img
            src="@/assets/images/icons/business.svg"
            alt="icons"
            class="h-10 w-10"
          />

          <h4 class="text-center text-white font-bold mt-5">
            Signage for <br />
            Un-Registered Business
          </h4>

          <p class="text-center font-medium text-sm text-iguGreen px-10 mt-2.5">
            Use this if your business is is not yet incorporated
          </p>

          <router-link
            :to="{
              name: 'register-unregistered-business',
              query: { st: page },
            }"
            @click.native="closeModal"
            class="mt-5 rounded-xl bg-ansLemon py-3.5 text-white px-14"
          >
            Start
          </router-link>
        </section>

        <section
          class="bg-ansGreen w-12/12 lg:w-4/12 rounded-xl flex flex-col justify-center items-center py-8 mt-7 lg:mt-0"
        >
          <img
            src="@/assets/images/icons/business.svg"
            alt="icons"
            class="h-10 w-10"
          />

          <h4 class="text-center text-white font-bold mt-5">
            Signage for <br />
            Registered Business
          </h4>

          <p class="text-center font-medium text-sm text-iguGreen px-10 mt-2.5">
            Use this if your business is already incorporated
          </p>

          <router-link
            :to="{ name: 'register-registered-business', query: { st: 1 } }"
            @click.native="closeModal"
            class="mt-5 rounded-xl bg-ansLemon py-3.5 text-white px-14"
          >
            Start
          </router-link>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "GreenModal",
  computed: {
    page() {
      return this.$store.state.profile.nin ? 5 : 1;
    },
  },
  async mounted() {
    this.$store.commit("wipeRegister");
    await this.$store.dispatch("getBusinessCategories");
  },
  methods: {
    closeModal() {
      this.$store.commit("toggleSignageModal");
    },
  },
};
</script>

<style scoped>
.cover-overlay {
  @apply fixed inset-0  h-screen w-screen overflow-hidden bg-gray-500 bg-opacity-25;
  @apply flex flex-col justify-center items-center lg:items-end;
}
.inner {
  @apply w-11/12 lg:w-7/12 h-auto lg:h-screen block lg:flex flex-col justify-center;
  min-width: 23rem;
}
</style>
